import { Controller } from '@hotwired/stimulus'

// This controller is responsible for insert additional breakdown fieldsets
// onto a billable form. It also wires up the breakdown modal to trigger rate
// recalculation.

// Connects to data-controller="billables--breakdowns"
export default class extends Controller {
  static targets = [
    'breakdowns',
    'total',
    'description',
    'baseRate',
    'count',
    'delete',
    'addBtn',
  ]
  static values = {
    count: Number,
    currency: String,
    source: String,
  }

  initialize() {
    this.refreshEvent = new Event('change')
  }

  connect() {
    this.element.addEventListener('shown.bs.modal', () => {
      this.addBtnTarget.focus()
    })

    this.element.addEventListener('hidden.bs.modal', () => {
      if (this.hasBaseRateTarget) {
        let total = 0

        for (let i = 0; i < this.baseRateTargets.length; i++) {
          if (!this.deleteTargets[i].checked) {
            const baseRate = parseFloat(this.baseRateTargets[i].value)
            const count = parseInt(this.countTargets[i].value)
            total += baseRate * count
          }
        }
        this.totalTarget.value = total.toFixed(2)
        this.totalTarget.dispatchEvent(this.refreshEvent)
      }
    })
  }

  addRate() {
    const url = new URL(this.sourceValue, window.location)
    url.searchParams.append('i', this.countValue)
    url.searchParams.append('currency', this.currencyValue)

    fetch(url, { redirect: 'error' })
      .then(response => response.text())
      .then(content => {
        let fieldset = document.createElement('fieldset')
        fieldset.dataset.target = 'billables--breakdowns.fieldset'
        fieldset.innerHTML = content
        this.breakdownsTarget.appendChild(fieldset)
        this.descriptionTargets[this.countValue].focus()
        this.countValue = this.countValue + 1
      })
      .catch(error => {
        console.log('Failed to fetch breakdown fields:', error)
      })
  }
}
