import { Controller } from '@hotwired/stimulus'
import * as bootstrap from 'bootstrap'

// Connects to data-controller="popovers"
export default class extends Controller {
  connect() {
    const popovers = document.querySelectorAll('[data-bs-toggle="popover"]')
    popovers.forEach(element => new bootstrap.Popover(element))
  }
}
