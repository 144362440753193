import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['targetName', 'input', 'submit']

  updateDialog() {
    this.targetNameTargets.forEach(element => {
      element.innerText = this.proposedTarget
    })
    this.submitTarget.disabled = !this.targetHasBeenEntered()
  }

  targetHasBeenEntered() {
    return !!this.inputTarget.value.trim()
  }

  get proposedTarget() {
    if (this.targetHasBeenEntered()) {
      return this.inputTarget.value
    } else {
      return '???'
    }
  }
}
