import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

// Connects to data-controller="remittances"
export default class extends Controller {
  connect() {
    const modal = new Modal(`#${this.element.id}`)
    modal.show()
  }
}
