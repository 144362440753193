import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="preferences"
export default class extends Controller {
  static values = { displayMode: String }

  connect() {
    console.log(this.displayModeValue)
    console.log(this.defaultDisplayMode)
    console.log(this.browserPreference)

    switch (this.displayModeValue) {
      case 'light':
        document.body.dataset.bsTheme = 'light'
        this.browserPreference.removeEventListener('change', event =>
          this.updateDisplayMode(event),
        )
        break
      case 'dark':
        document.body.dataset.bsTheme = 'dark'
        this.browserPreference.removeEventListener('change', event =>
          this.updateDisplayMode(event),
        )
        break
      default:
        document.body.dataset.bsTheme = this.defaultDisplayMode
        this.browserPreference.addEventListener('change', event =>
          this.updateDisplayMode(event),
        )
    }
  }

  get defaultDisplayMode() {
    return this.browserPreference.matches ? 'dark' : 'light'
  }

  get browserPreference() {
    if (window.matchMedia) {
      return window.matchMedia('(prefers-color-scheme: dark)')
    }
  }

  updateDisplayMode(event) {
    document.body.dataset.bsTheme = event.matches ? 'dark' : 'light'
  }
}
