import { Controller } from '@hotwired/stimulus'
import * as bootstrap from 'bootstrap'

// Connects to data-controller="tooltips"
export default class extends Controller {
  connect() {
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    tooltips.forEach(element => new bootstrap.Tooltip(element))
  }
}
