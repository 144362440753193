import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'conversionRate',
    'currency',
    'symbol',
    'name',
    'breakdowns',
  ]
  static values = { source: String }

  initialize() {
    this.refreshEvent = new Event('change')

    this.symbols = {
      usd: '<i class="bi-currency-dollar"></i>',
      gbp: '<i class="bi-currency-pound"></i>',
      eur: '<i class="bi-currency-euro"></i>',
    }
  }

  fetchConversionRate() {
    const url = this.sourceValue.replace(
      ':code',
      encodeURIComponent(this.currency),
    )

    fetch(url)
      .then(response => response.json())
      .then(rate => {
        this.setCurrency()
        this.setConversionRate(rate)
        this.setName()
        this.showSuccess()
      })
      .catch(error => {
        console.log('Failed to retrieve exchange rate:', error)
        this.showFailure()
      })
  }

  setCurrency() {
    this.symbolTargets.forEach(element => {
      element.innerHTML = this.symbols[this.currency]
    })
    this.element.dataset['billables-BreakdownsCurrencyValue'] = this.currency
  }

  setConversionRate(rate) {
    this.conversionRateTarget.value = rate
    this.conversionRateTarget.readOnly = this.isUSD()
    this.conversionRateTarget.dispatchEvent(this.refreshEvent)
  }

  setName() {
    this.nameTarget.innerText = this.currency.toUpperCase()
  }

  showSuccess() {
    this.conversionRateTarget.classList.add('has-success')
    this.conversionRateTarget.classList.remove('has-error')
  }

  showFailure() {
    this.conversionRateTarget.classList.remove('has-success')
    this.conversionRateTarget.classList.add('has-error')
  }

  get currency() {
    return this.currencyTarget.value
  }

  isUSD() {
    return this.currency === 'usd'
  }
}
