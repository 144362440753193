import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['plus10', 'baseRate']
  static values = { outstanding: Number }

  initialize() {
    this.refreshEvent = new Event('change')
  }

  payInFull() {
    const newValue = this.outstandingValue / this.multiplier
    this.baseRate.value = newValue.toFixed(2)
    this.baseRate.dispatchEvent(this.refreshEvent)
  }

  get baseRate() {
    return this.baseRateTarget
  }

  get multiplier() {
    return this.isPlus10() ? 1.1 : 1.0
  }

  isPlus10() {
    return this.plus10Target.checked
  }
}
