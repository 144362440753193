import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fieldset', 'button', 'autofocus']

  enableFieldset() {
    this.fieldsetTarget.classList.remove('d-none')
    this.fieldsetTarget.disabled = false
    if (this.hasAutofocusTarget) {
      this.autofocusTarget.focus()
    }
    this.buttonTargets.forEach(button => {
      button.classList.toggle('d-none')
    })
  }

  disableFieldset() {
    this.fieldsetTarget.classList.add('d-none')
    this.fieldsetTarget.disabled = true
    this.buttonTargets.forEach(button => {
      button.classList.toggle('d-none')
    })
  }
}
