import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['aggregate', 'item', 'form']

  toggleOne(event) {
    const item = event.target

    if (item.checked) {
      this.appendHiddenInput(item)
    } else {
      this.removeHiddenInput(item)
    }

    if (this.allAreChecked) {
      this.aggregateTarget.checked = true
      this.aggregateTarget.indeterminate = false
    } else if (this.someAreChecked) {
      this.aggregateTarget.indeterminate = true
    } else {
      this.aggregateTarget.checked = false
      this.aggregateTarget.indeterminate = false
    }
  }

  toggleAll(event) {
    const aggregate = event.target

    if (aggregate.checked) {
      this.itemTargets.forEach(item => {
        if (!item.checked) {
          item.checked = true
          this.appendHiddenInput(item)
        }
      })
    } else {
      this.itemTargets.forEach(item => {
        if (item.checked) {
          item.checked = false
          this.removeHiddenInput(item)
        }
      })
    }
  }

  appendHiddenInput(item) {
    this.formTargets.forEach(form => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'billable_ids[]'
      input.value = item.value
      form.appendChild(input)
    })
  }

  removeHiddenInput(item) {
    this.formTargets.forEach(form => {
      const input = form.querySelector(
        `[name="billable_ids[]"][value="${item.value}"]`,
      )
      if (input) {
        form.removeChild(input)
      }
    })
  }

  get allAreChecked() {
    return this.itemTargets.every(item => item.checked)
  }

  get someAreChecked() {
    return this.itemTargets.some(item => item.checked)
  }
}
