import { Controller } from '@hotwired/stimulus'

// This controller is responsible for applying scale rates when filling out
// breakdowns.

// Connects to data-controller="breakdowns"
export default class extends Controller {
  static outlets = ['scale-rates']
  static targets = ['name', 'baseRate']
  static values = { baseRate: Number, refresh: Boolean }

  chooseRate(event) {
    const baseRate = this.scaleRatesOutlet.chooseRate(event.target.value)

    this.refreshValue = true
    if (baseRate) {
      this.baseRateValue = baseRate
    }
  }

  baseRateValueChanged() {
    if (this.refreshValue) {
      this.baseRateTarget.value = this.baseRateValue
    }
  }
}
