import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['date', 'rate', 'source']

  fetchCommissionRate(event) {
    /*
     * The "change" event fires on every keypress as soon as a parseable date
     * is present. This causes a race condition as the action is fired with
     * dates that look complete but aren't. Work around this by aborting if
     * the year is less than four digits long.
     */
    if (!this.date || parseInt(this.date.split('-')[0]) < 1000) {
      return
    }

    const url = event.params.url.replace(':date', encodeURIComponent(this.date))

    fetch(url)
      .then(response => response.json())
      .then(rate => {
        this.setRate(rate.rate)
        this.setSource(rate.source)
        this.showSuccess()
        this.publish()
      })
      .catch(error => {
        console.log('Failed to retrieve commission rate:', error)
        this.showFailure()
      })
  }

  setRate(rate) {
    this.rateTarget.value = rate
  }

  setSource(source) {
    this.sourceTarget.value = source
  }

  showSuccess() {
    ;[this.rateTarget, this.sourceTarget].forEach(input => {
      input.classList.remove('is-invalid')
      input.classList.add('is-valid')
    })
  }

  showFailure() {
    ;[this.rateTarget, this.sourceTarget].forEach(input => {
      input.classList.remove('is-valid')
      input.classList.add('is-invalid')
    })
  }

  publish() {
    const changed = new Event('change')
    this.rateTarget.dispatchEvent(changed)
  }

  get date() {
    return this.dateTarget.value
  }
}
