import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="scale-rates"
export default class extends Controller {
  static targets = ['option']

  chooseRate(name) {
    const rate = this.optionTargets.find(
      option => option.value.toLowerCase() === name.toLowerCase(),
    )

    if (rate) {
      return rate.dataset.baseRate
    }
  }
}
