import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'rawRate',
    'currency',
    'conversionRate',
    'baseRate',
    'plus10',
    'grossRate',
    'fees',
    'adjustedGrossRate',
    'agencyRate',
    'agencySource',
    'agencyCommission',
    'managerRate',
    'managerSource',
    'managerCommission',
    'netRate',
  ]

  updateBaseRate() {
    const newValue = this.rawRate / this.conversionRate
    this.baseRateTarget.value = newValue.toFixed(2)
    this.updateGrossRate()
  }

  updateGrossRate() {
    const newValue = this.baseRate * this.multiplier
    this.grossRateTarget.value = newValue.toFixed(2)
    this.updateAdjustedGrossRate()
  }

  updateAdjustedGrossRate() {
    const newValue = this.grossRate - this.fees
    this.adjustedGrossRateTarget.value = newValue.toFixed(2)
    this.updateAgencyCommission()
    this.updateManagerCommission()
  }

  updateAgencyCommission() {
    const newValue = this.agencySourceRate * this.agencyRate
    this.agencyCommissionTarget.value = newValue.toFixed(2)
    this.updateNetRate()
  }

  updateManagerCommission() {
    const newValue = this.managerSourceRate * this.managerRate
    this.managerCommissionTarget.value = newValue.toFixed(2)
    this.updateNetRate()
  }

  updateNetRate() {
    if (this.hasNetRateTarget) {
      const commission = this.agencyCommission ? this.agencyCommission : 0.0
      const fees = this.fees ? this.fees : 0.0
      const newValue = this.grossRate - fees - commission
      this.netRateTarget.value = newValue.toFixed(2)
    }
  }

  get rawRate() {
    return parseFloat(this.rawRateTarget.value)
  }

  get conversionRate() {
    return parseFloat(this.conversionRateTarget.value)
  }

  get baseRate() {
    return parseFloat(this.baseRateTarget.value)
  }

  get multiplier() {
    return this.isPlus10() ? 1.1 : 1.0
  }

  get grossRate() {
    return parseFloat(this.grossRateTarget.value)
  }

  get fees() {
    return parseFloat(this.feesTarget.value)
  }

  get adjustedGrossRate() {
    return parseFloat(this.adjustedGrossRateTarget.value)
  }

  get agencyRate() {
    return parseFloat(this.agencyRateTarget.value) / 100.0
  }

  get agencySourceRate() {
    switch (this.agencySourceTarget.value) {
      case 'base_rate':
        return this.baseRate
      case 'gross_rate':
        return this.grossRate
      case 'adjusted_gross_rate':
        return this.adjustedGrossRate
    }
  }

  get agencyCommission() {
    return parseFloat(this.agencyCommissionTarget.value)
  }

  get managerRate() {
    return parseFloat(this.managerRateTarget.value) / 100.0
  }

  get managerSourceRate() {
    switch (this.managerSourceTarget.value) {
      case 'base_rate':
        return this.baseRate
      case 'gross_rate':
        return this.grossRate
      case 'adjusted_gross_rate':
        return this.adjustedGrossRate
    }
  }

  get managerCommission() {
    return parseFloat(this.managerCommissionTarget.value)
  }

  get netRate() {
    return parseFloat(this.netRateTarget.value)
  }

  isPlus10() {
    return this.plus10Target.checked
  }
}
