import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['hidden', 'start', 'success', 'failure', 'valid', 'invalid']
  static targets = ['receipts', 'button', 'icon', 'spinner']
  static values = { source: String }

  fetchReceipts(event) {
    this.onStart()

    fetch(this.sourceValue)
      .then(response => response.json())
      .then(receipts => {
        this.addReceipts(receipts)
        this.onSuccess()
      })
      .catch(error => {
        console.log('Failed to retrieve receipts:', error)
        this.onFailure()
      })
  }

  onStart() {
    this.button.classList.add(this.startClass)
    this.button.classList.remove(this.successClass, this.failureClass)
    this.icon.classList.add(this.hiddenClass)
    this.spinner.classList.remove(this.hiddenClass)
    this.receipts.classList.remove(this.validClass, this.invalidClass)
    this.button.disabled = true
  }

  onSuccess() {
    this.icon.classList.remove(this.hiddenClass)
    this.spinner.classList.add(this.hiddenClass)
    this.button.classList.add(this.successClass)
    this.button.classList.remove(this.startClass)
    this.receipts.classList.add(this.validClass)
    this.receipts.classList.remove(this.invalidClass)
    this.receipts.focus()
  }

  onFailure() {
    this.spinner.classList.add(this.hiddenClass)
    this.icon.classList.remove(this.hiddenClass)
    this.button.classList.add(this.failureClass)
    this.button.classList.remove(this.startClass)
    this.receipts.classList.add(this.invalidClass)
    this.receipts.classList.remove(this.validClass)
    this.button.disabled = false
  }

  addReceipts(receipts) {
    const optGroup = document.createElement('optgroup')

    optGroup.label = 'All receipts'
    receipts.forEach(receipt => {
      const option = document.createElement('option')
      option.value = receipt.id
      option.innerText = receipt.to_label
      optGroup.appendChild(option)
    })
    this.receipts.appendChild(optGroup)
  }

  get receipts() {
    return this.receiptsTarget
  }

  get button() {
    return this.buttonTarget
  }

  get icon() {
    return this.iconTarget
  }

  get spinner() {
    return this.spinnerTarget
  }
}
