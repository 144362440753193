import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['name', 'commissionSource']
  static values = { name: String, refresh: Boolean, url: String }

  refreshVendor() {
    this.refreshValue = true
    this.nameValue = this.nameTarget.value
  }

  nameValueChanged() {
    if (this.refreshValue) {
      this.lookupVendor(this.urlValue).then(vendor => {
        const source = vendor.agency_source || 'gross_rate'
        const path = vendor.scale_rates

        this.selectCommissionSource(source)
        this.refreshScaleRates(path)
      })
    }
  }

  async lookupVendor(source) {
    const url = new URL(source, window.location)
    url.searchParams.append('name', this.nameValue)
    console.log(`Lookup vendor: ${url}`)

    const response = await fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }
        return response
      })
      .catch(error => {
        console.log(`Request to ${url} failed:`, error)
      })
    return response.json()
  }

  selectCommissionSource(source) {
    const changed = new Event('change')
    const input = this.commissionSourceTarget
    const option = input.querySelector(`option[value="${source}"]`)
    option.selected = true
    input.classList.add('is-valid')
    input.dispatchEvent(changed)
  }

  refreshScaleRates(path) {
    if (path) {
      Turbo.visit(path, { frame: 'scale_rates_datalist' })
    }
  }
}
